<template>
  <!--왼쪽 최신,인기,리그 탭-->

    <div class="left_section" :class="{'left_section_show':isShowLeftBar}">
      <div class="left_cont">
        <div class="btn-close"  @click="hideLeftBar()">
          <i class="fa fa-close"></i>
        </div>
        <slot></slot>
      </div>
    </div>
</template>

<script>
  export default {
    name: "LeftBarComp",
    data() {
      return {
        isShowLeftBar: false
      }
    },
    methods: {
      hideLeftBar() {
        this.isShowLeftBar = false;
      }
    },
    created() {
      //모바일 하단메뉴 베팅카드 메뉴 클릭시 오른쪽메뉴 클릭감시
      //MobileSportsFootMenuComp.vue에서 $emit
      this.$bus.$on('leftBarHandle', (isShow)=>{
        this.isShowLeftBar = isShow
      })
    }
  }
</script>

<style scoped>

</style>